import React, { useEffect, useRef, useState, useCallback } from 'react';
import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';
import cornerstoneTools from 'cornerstone-tools';
import JSZip from 'jszip';
import * as nifti from 'nifti-reader-js';
import pako from 'pako';

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneTools.external.cornerstone = cornerstone;

const flipVertical = (data, width, height) => {
  const flipped = new Uint8Array(data.length);
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      flipped[y * width + x] = data[(height - 1 - y) * width + x];
    }
  }
  return flipped;
};

const calculateNiftiSliceIndex = (dicomImage, niftiHeader) => {
  const dicomSlicePositionString = dicomImage.data.string('x00200032');
  if (!dicomSlicePositionString) {
    console.error('Missing Image Position (Patient) in DICOM');
    return 0;
  }
  const dicomSlicePosition = parseFloat(dicomSlicePositionString.split('\\')[2]);
  if (isNaN(dicomSlicePosition)) {
    console.error('Invalid Image Position (Patient) in DICOM');
    return 0;
  }
  const niftiSlicePosition = (dicomSlicePosition - niftiHeader.qoffset_z) / niftiHeader.pixDims[3];
  const sliceIndex = Math.round(niftiSlicePosition);
  
  return Math.max(0, Math.min(sliceIndex, niftiHeader.dims[3] - 1));
};

const sortDicomFiles = (files) => {
  return files.sort((a, b) => {
    const aPosition = a.imagePositionPatient ? parseFloat(a.imagePositionPatient.split('\\')[2]) : null;
    const bPosition = b.imagePositionPatient ? parseFloat(b.imagePositionPatient.split('\\')[2]) : null;
    
    if (aPosition !== null && bPosition !== null) {
      return aPosition - bPosition;
    }

    if (a.sliceLocation && b.sliceLocation) {
      return parseFloat(a.sliceLocation) - parseFloat(b.sliceLocation);
    }

    return parseInt(a.instanceNumber, 10) - parseInt(b.instanceNumber, 10);
  });
};

const sortByFilename = (files) => {
  return files.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    return nameA.localeCompare(nameB, undefined, { numeric: true, sensitivity: 'base' });
  });
};

const checkAndReverse = (imageUrls) => {
  if (imageUrls.length > 1) {
      const firstPos = parseFloat(imageUrls[0].imagePositionPatient?.split('\\')[2]);
      const lastPos = parseFloat(imageUrls[imageUrls.length - 1].imagePositionPatient?.split('\\')[2]);
      if (!isNaN(firstPos) && !isNaN(lastPos) && firstPos > lastPos) {
      console.log('Detected reverse order, correcting...');
      return imageUrls.reverse();
      }
  }
  return imageUrls;
};

const DicomNiftiViewer = ({ subnum, zipUrl, niftiUrls }) => {
  const elementRef = useRef(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [niftiData, setNiftiData] = useState([]);
  const [niftiHeaders, setNiftiHeaders] = useState([]);

  const prepareNiftiOverlays = useCallback((dicomImage) => {
    console.log('Preparing NIFTI overlays');

    if (!niftiData.length || !niftiHeaders.length || !dicomImage) {
      console.log('Skipping NIFTI overlays due to missing data');
      return [];
    }

    return niftiData.map((data, index) => {
      const header = niftiHeaders[index];
      const { color } = niftiUrls[index];

      if (dicomImage.width !== header.dims[1] || dicomImage.height !== header.dims[2]) {
        console.error(`Dimension mismatch for ${niftiUrls[index].name}!`);
        return null;
      }

      const sliceIndex = calculateNiftiSliceIndex(dicomImage, header);
      const sliceSize = header.dims[1] * header.dims[2];
      const sliceData = new Uint8Array(data.slice(sliceIndex * sliceSize, (sliceIndex + 1) * sliceSize));

      if (sliceData.every(value => value === 0)) {
        console.log(`No non-zero values in NIFTI slice for overlay ${index}`);
        return null;
      }

      let transformedSliceData = flipVertical(sliceData, header.dims[1], header.dims[2]);
      const coloredSliceData = new Uint8ClampedArray(sliceSize * 4);

      for (let i = 0; i < sliceSize; i++) {
        const value = transformedSliceData[i];
        const index = i * 4;
        if (value > 0) {
          coloredSliceData[index] = color.r;
          coloredSliceData[index + 1] = color.g;
          coloredSliceData[index + 2] = color.b;
          coloredSliceData[index + 3] = color.a;
        } else {
          coloredSliceData[index + 3] = 0;
        }
      }

      return {
        imageId: `niftiOverlay_${index}`,
        minPixelValue: 0,
        maxPixelValue: 255,
        slope: 1.0,
        intercept: 0,
        windowCenter: 127,
        windowWidth: 256,
        getPixelData: () => coloredSliceData,
        rows: header.dims[2],
        columns: header.dims[1],
        height: header.dims[2],
        width: header.dims[1],
        color: true,
        rgba: true,
        columnPixelSpacing: dicomImage.columnPixelSpacing,
        rowPixelSpacing: dicomImage.rowPixelSpacing,
        sizeInBytes: coloredSliceData.length
      };
    }).filter(overlay => overlay !== null);
  }, [niftiData, niftiHeaders, currentIndex, niftiUrls, imageUrls.length]);

  const loadImage = useCallback(async (index) => {
    if (imageUrls.length > 0 && elementRef.current) {
      try {
        console.log(`Loading DICOM image at index ${index}`);
        const image = await cornerstone.loadAndCacheImage(imageUrls[index]);

        cornerstone.getLayers(elementRef.current).forEach(layer => {
          cornerstone.removeLayer(elementRef.current, layer.layerId);
        });

        cornerstone.addLayer(elementRef.current, image, { opacity: 1, name: 'dicomBase' });

        const viewport = cornerstone.getViewport(elementRef.current);
        viewport.voi.windowWidth = 400;
        viewport.voi.windowCenter = 40;
        cornerstone.setViewport(elementRef.current, viewport);

        const niftiOverlays = prepareNiftiOverlays(image);
        niftiOverlays.forEach((overlay, index) => {
          if (overlay) {
            console.log(`Adding NIFTI overlay ${index}`);
            cornerstone.addLayer(elementRef.current, overlay, { opacity: 0.5, name: `niftiOverlay_${index}` });
          }
        });

        cornerstone.updateImage(elementRef.current);
        console.log('Image updated with layers');
      } catch (error) {
        console.error(`Error loading or displaying image at index ${index}:`, error);
      }
    }
  }, [imageUrls, prepareNiftiOverlays]);

  const handleSliderChange = (event) => {
    const newIndex = parseInt(event.target.value, 10);
    setCurrentIndex(newIndex);
    loadImage(newIndex);
  };

  useEffect(() => {
    cornerstone.enable(elementRef.current);

    const loadZipAndImages = async () => {
      try {
        console.log('Fetching DICOM .gz file from:', zipUrl);
        const response = await fetch(zipUrl);
        if (!response.ok) {
          throw new Error(`Network response was not ok while fetching DICOM .gz file: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();
        console.log('Fetched DICOM file successfully.');
        console.log('Decompressing and loading DICOM .gz file...');

        const zip = await JSZip.loadAsync(arrayBuffer);
        const dicomFiles = [];
        zip.forEach((relativePath, file) => {
          if (relativePath.startsWith('00-dicom/') && relativePath.endsWith('.dcm')) {
            dicomFiles.push(file);
          }
        });

        if (dicomFiles.length === 0) {
          console.error('No DICOM files found in the ZIP archive.');
          return;
        }
        let sortedDicomFiles = sortByFilename(dicomFiles);
        const loadedImageUrls = await Promise.all(
          sortedDicomFiles.map(async (file, index) => {
            try {
              const dicomBlob = await file.async('blob');
              const arrayBuffer = await dicomBlob.arrayBuffer();
              const dicomData = dicomParser.parseDicom(new Uint8Array(arrayBuffer));
        
              const instanceNumber = dicomData.string('x00200013');
              const sliceLocation = dicomData.string('x00201041');
              const imagePositionPatient = dicomData.string('x00200032');
        
              const url = URL.createObjectURL(dicomBlob);
              return {
                url: `wadouri:${url}`,
                instanceNumber,
                sliceLocation,
                imagePositionPatient,
                fileName: file.name
              };
            } catch (error) {
              console.error(`Error processing file ${index}:`, error);
              return null;
            }
          })
        );

        console.log('Decompressed and loaded DICOM file successfully.');

        const validImageUrls = loadedImageUrls.filter(item => item !== null);
        const correctedImageUrls = checkAndReverse(validImageUrls);
        const sortedValidImageUrls = sortDicomFiles(correctedImageUrls);

        const finalImageUrls = sortedValidImageUrls.map(item => item.url);
        setImageUrls(finalImageUrls);

        if (finalImageUrls.length > 0) {
          loadImage(0);
        } else {
          console.error('No valid DICOM images found to display.');
        }
      } catch (error) {
        console.error('Error loading DICOM ZIP archive:', error);
      }
    };

    loadZipAndImages();

    return () => {
      cornerstone.disable(elementRef.current);
      imageUrls.forEach((url) => URL.revokeObjectURL(url.replace('wadouri:', '')));
    };
  }, [zipUrl]);

  useEffect(() => {
    console.log(`Current index changed to: ${currentIndex}`);
  }, [currentIndex]);

  useEffect(() => {
    const loadNiftiMasks = async () => {
      try {
        const loadedData = [];
        const loadedHeaders = [];

        for (const { url } of niftiUrls) {
          console.log('Fetching NIFTI mask .nii.gz file from:', url);
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`Network response was not ok while fetching NIFTI .nii.gz mask file: ${response.statusText}`);
          }
          const arrayBuffer = await response.arrayBuffer();
          const decompressedData = pako.inflate(arrayBuffer);

          const inflatedBuffer = nifti.Utils.toArrayBuffer(decompressedData);
          if (nifti.isNIFTI(inflatedBuffer)) {
            const header = nifti.readHeader(inflatedBuffer);
            const image = nifti.readImage(header, inflatedBuffer);
            loadedHeaders.push(header);
            loadedData.push(image);
            console.log('NIFTI image loaded');
          } else {
            console.error('Not a valid NIFTI file');
          }
        }

        setNiftiHeaders(loadedHeaders);
        setNiftiData(loadedData);
      } catch (error) {
        console.error('Error loading NIFTI files:', error);
      }
    };

    loadNiftiMasks();
  }, [niftiUrls]);

  return (
    <div style={{ height: '512px', position: 'relative' }}>
      <div
        ref={elementRef}
        style={{ width: '100%', height: '100%', backgroundColor: 'black' }}
      />
      <div style={{ textAlign: 'center' }}>
        <input
          type="range"
          min="0"
          max={imageUrls.length - 1}
          value={currentIndex}
          onChange={handleSliderChange}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
};

export default DicomNiftiViewer;