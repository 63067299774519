import React, { useEffect, useState } from "react";
import {useLocation, useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';

import IconButton from '@mui/material/IconButton';
import { ReactComponent as ScapulaIcon } from '../images/Scapula.svg';
import { ReactComponent as HumerusIcon } from '../images/Humerus.svg';
import { ReactComponent as ClavicleIcon } from '../images/Clavicle.svg';
import { ReactComponent as DeltoidIcon } from '../images/Deltoid.svg';
import { ReactComponent as InfraspinatusIcon } from '../images/Infraspinatus.svg';
import { ReactComponent as SupraspinatusIcon } from '../images/Supraspinatus.svg';
import { ReactComponent as SubscapularisIcon } from '../images/Subscapularis.svg';

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";
import StlViewer from "../imageViewers/StlViewer";
import DicomNiftiViewer from '../imageViewers/DicomNiftiViewer';
import LabResultsGrid from '../components/LabResultsGrid';
import { componentColorMap } from '../components/organColorMapping';
import ShoulderCaseLabeling from '../components/ShoulderCaseLabeling';


const caseDetailsCommonCellStyle = {
  fontFamily: "Open Sans",
  fontStyle: 'normal',
  lineHeight: '20px',
  color: '#F9F9F9',
  borderBottom: 'none'
};

const caseDetailsHeaderCellStyle = {
  ...caseDetailsCommonCellStyle,
  fontSize: '16px',
  fontWeight: 700,
};

const caseDetailsBodyCellStyle = {
  ...caseDetailsCommonCellStyle,
  fontSize: '14px',
  fontWeight: 400,
};

const shoulderComponentButtonStyles = {
  width: '175px',
  height: '75px',
  backgroundColor: '#444',
  color: '#F9F9F9',
  textAlign: 'right',
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
  letterSpacing: '1px',
  textTransform: 'none',
};

const shoulderComponentIconBoxStyles = (borderColor, sx) => ({
  border: `1px solid ${borderColor}`,
  borderRadius: '5px',
  padding: '8px',
  marginLeft: '20px',
  cursor: 'pointer',
  ...sx,
});

const shoulderComponentIconTextStyles = () => ({
  textAlign: 'right',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
  letterSpacing: '1px',
});

const ShoulderComponentIconBox = ({ icon: Icon, label, borderColor, color, onClick, sx, disabled }) => (
  <Box 
    display="inline-flex" 
    flexDirection="column" 
    alignItems="center" 
    sx={{
      ...shoulderComponentIconBoxStyles(borderColor, sx),
      cursor: disabled ? 'not-allowed' : 'pointer',
    }}
    onClick={disabled ? null : onClick}
  >
    <IconButton disabled={disabled}>
      <Icon />
    </IconButton>
    <Typography 
      sx={{ 
        ...shoulderComponentIconTextStyles(), 
        color: disabled ? 'gray' : (sx?.color || color),
      }}
    >
      {label}
    </Typography>
  </Box>
);

const boneShoulderComponents = [
  { icon: ScapulaIcon, label: 'Scapula', name: 'scapula' },
  { icon: HumerusIcon, label: 'Humerus', name: 'humerus' },
  { icon: ClavicleIcon, label: 'Clavicle', name: 'clavicle' },
];

const muscleShoulderComponents = [
  { icon: DeltoidIcon, label: 'Deltoid', name: 'deltoid' },
  { icon: InfraspinatusIcon, label: 'Infraspinatus', name: 'infraspinatus' },
  { icon: SupraspinatusIcon, label: 'Supraspinatus', name: 'supraspinatus' },
  { icon: SubscapularisIcon, label: 'Subscapularis', name: 'subscapularis' },
];

const BonesShoulderComponentIconBoxes = ({ selectedComponents, handleIconClick }) => (
  <Grid item xs={4.5}>
    {boneShoulderComponents.map((component) => (
      <ShoulderComponentIconBox
        key={component.name}
        icon={component.icon}
        label={component.label}
        borderColor="#1B98E0"
        color="#0092E3"
        onClick={() => component.name === 'scapula' ? handleIconClick(component.name) : null}
        disabled={component.name !== 'scapula'}
        sx={{
          backgroundColor: selectedComponents.includes(component.name) ? '#0092E3' : 'transparent',
          color: selectedComponents.includes(component.name) ? '#F9F9F9' : '#0092E3',
          opacity: component.name !== 'scapula' ? 0.5 : 1,
        }}
      />
    ))}
  </Grid>
);

const MuscleShoulderComponentIconBoxes = ({ selectedComponents, handleIconClick }) => (
  <Grid item xs={6}>
    {muscleShoulderComponents.map((component) => (
      <ShoulderComponentIconBox
        key={component.name}
        icon={component.icon}
        label={component.label}
        borderColor="#AE1FD6"
        color="#AE1FD6"
        onClick={() => handleIconClick(component.name)}
        sx={{
          backgroundColor: selectedComponents.includes(component.name) ? '#AE1FD6' : 'transparent',
          color: selectedComponents.includes(component.name) ? '#F9F9F9' : '#AE1FD6',
        }}
      />
    ))}
  </Grid>
);

const componentConfigs = {
  scapula: { 
    // bone: [{ url: 'scapula', color: 'cyan' }],
    default: [{ url: 'scapula', color: 'cyan' }]
  },
  // humerus: { 
  //   bone: [{ url: 'humerus', color: 'gold' }],
  //   default: [{ url: 'humerus', color: 'gold' }]
  // },
  // clavicle: { 
  //   bone: [{ url: 'clavicle', color: 'lightblue' }],
  //   default: [{ url: 'clavicle', color: 'lightblue' }] 
  // },
  deltoid: {
    muscle: [{ url: 'deltoidFat', color: '#e6dc46' },{ url: 'deltoidMuscle', color: '#c06858' }],
    default: { url: 'deltoid', color: 'orange' }
  },
  infraspinatus: {
    muscle: [{ url: 'infraspinatusFat', color: '#e6dc46' },{ url: 'infraspinatusMuscle', color: '#c06858' }],
    default: { url: 'infraspinatus', color: 'pink' }
  },
  subscapularis: {
    muscle: [{ url: 'subscapularisFat', color: '#e6dc46' }, { url: 'subscapularisMuscle', color: '#c06858' }],
    default: { url: 'subscapularis', color: 'yellow' }
  },
  supraspinatus: {
    muscle: [{ url: 'supraspinatusFat', color: '#e6dc46' }, { url: 'supraspinatusMuscle', color: '#c06858' }],
    default: { url: 'supraspinatus', color: 'green' }
  }
};

const getAllComponentUrls = (component, stlUrls) => {
  const config = componentConfigs[component];
  if (!config) return [];

  const urls = [];
  const addUrl = (key, color, type) => {
    const url = stlUrls[key];
    if (url) urls.push({ url, color, type });
  };

  if (config.bone) {
    addUrl(component, config.bone.color, 'bone');
  }
  if (config.muscle) {
    addUrl(`${component}Fat`, config.muscle[0].color, 'muscle');
    addUrl(`${component}Muscle`, config.muscle[1].color, 'muscle');
  }
  if (config.default) {
    addUrl(component, config.default.color, 'default');
  }

  return urls;
};

const createUrlsList = (stlUrls) => {
  const urlsList = [];
  const excludedComponents = ['humerus', 'clavicle'];

  Object.keys(componentConfigs).forEach(component => {
    if (!excludedComponents.includes(component)) {
      const componentUrls = getAllComponentUrls(component, stlUrls);
      componentUrls.forEach(urlObj => {
        if (urlObj.url) {
          urlsList.push({
            name: component,
            url: urlObj.url,
            color: urlObj.color,
            type: urlObj.type
          });
        }
      });
    }
  });

  console.log("createUrlsList: Created urlsList", urlsList);
  return urlsList;
};

const ShoulderSTLViewer = ({ selectedComponents, selectedQuality, stlUrls }) => {
  const [urlsList, setUrlsList] = useState([]);

  useEffect(() => {
    console.log("ShoulderSTLViewer: Creating urlsList");
    const newUrlsList = createUrlsList(stlUrls);
    console.log("ShoulderSTLViewer: urlsList created", newUrlsList);
    setUrlsList(newUrlsList);
  }, [stlUrls]);

  if (urlsList.length === 0) {
    console.warn('No valid URLs found');
    return null;
  }

  return (
    <StlViewer 
      urls={urlsList}
      height="512px" 
      visibleModels={selectedComponents} 
      selectedQuality={selectedQuality}
    />
  );
};

export const SingleImageView = (props) => {
    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiIris.scopes
    });

    const location = useLocation();
    const navigate = useNavigate();
    const [subnum, setSubnum] = useState(location.state.subnum);
    const [image, setImage] = useState(null);
    const [isImageDetailsIsLoading, setIsImageDetailsIsLoading] = useState(true);
    const [caseDetailsTableRowData, setCaseDetailsTableRowData] = useState([]);
    const [rawLabResults, setRawLabResults] = useState();
    const [labResults, setLabResults] = useState();
    const [selectedComponents, setSelectedComponents] = useState(['deltoid']);
    const [selectedQuality, setSelectedQuality] = useState([]);
    const [lastClickedComponent, setLastClickedComponent] = React.useState('deltoid');
    const [lastSelectedComponent, setLastSelectedComponent] = React.useState('deltoid');
    
    useEffect(() => {
      if (subnum) {
        setIsImageDetailsIsLoading(true);

        execute('GET', `${protectedResources.apiIris.irisImageDetailsEndpoint}?subnum=${subnum}`)
          .then((response) => {
            if (response && response.metrics) {
              const { metrics, ...imageData } = response;
              setRawLabResults(metrics);
              
              return execute('GET', `${protectedResources.apiIris.feedbackEndpoint}/${encodeURIComponent(subnum)}`)
                .then((feedbackResponse) => {
                  if (Array.isArray(feedbackResponse) && feedbackResponse.length > 0) {
                    setImage({ ...imageData, feedback: feedbackResponse });
                  } else {
                    setImage({ ...imageData, feedback: null });
                  }
                });
            } else {
              console.error('Invalid response format:', response);
              setImage(null);
            }
          })
          .catch((error) => {
            console.error('Error fetching image details or feedback:', error);
            setImage(null);
          })
          .finally(() => {
            setIsImageDetailsIsLoading(false);
          });
      }
    }, [execute, subnum]);

    useEffect(() => {
      if (image) {
        setCaseDetailsTableRowData([
          { 
            cells: [
              `ID: ${image.subnum || 'N/A'}`, 
              `Preop Range of Motion: N/A`, 
              `Pain Daily Basis: ${image.preopPainDailyBasis_assessment || 'N/A'}`
            ], 
            paddingTop: 1, 
            paddingBottom: 0 
          },
          { 
            cells: [
              `${image.gender || 'N/A'} | ${image.age || 'N/A'} years | No prior surgery`, 
              `Active Forward Elevation: ${image.preopActiveForwardElev_movement || 'N/A'}`, 
              `Shoulder Function: ${image.preopShoulderFunction_score || 'N/A'}`
            ], 
            paddingTop: 0.5, 
            paddingBottom: 0 
          },
          { 
            cells: [
              `Diagnosis: ${image.diagnosis || 'N/A'}`, 
              `Active Abduction: ${image.preopActiveAbduction_movement || 'N/A'}`, 
              `ASES: ${image.preopAses_score || 'N/A'}`
            ], 
            paddingTop: 0.5, 
            paddingBottom: 0 
          },
          { 
            cells: [
              `Comorbidities: N/A`, 
              `Active External Rotation: ${image.preopActiveExtRot_movement || 'N/A'}`, 
              `CONSTANT: ${image.preopConstant_score || 'N/A'}`
            ], 
            paddingTop: 0.5, 
            paddingBottom: 1 
          }
        ]);

        const roundToTwoDecimals = (num) => {
          return Math.round((num + Number.EPSILON) * 100) / 100;
        };

        const roundObjectValues = (obj) => {
          const roundedObj = {};
          for (const key in obj) {
            if (typeof obj[key] === 'number') {
              roundedObj[key] = roundToTwoDecimals(obj[key]);
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
              roundedObj[key] = roundObjectValues(obj[key]);
            } else {
              roundedObj[key] = obj[key];
            }
          }
          return roundedObj;
        };

        const roundedLabResults = roundObjectValues(rawLabResults);
        setLabResults(roundedLabResults);
      }
    }, [image]);

    const handleIconClick = (component) => {
      setSelectedComponents((prevSelectedComponents) => {
        const isAlreadySelected = prevSelectedComponents.includes(component);
        const newSelectedComponents = isAlreadySelected
          ? prevSelectedComponents.filter((c) => c !== component)
          : [...prevSelectedComponents, component];
        
        if (isAlreadySelected) {
          if (newSelectedComponents.length > 0) {
            setLastClickedComponent(newSelectedComponents[newSelectedComponents.length - 1]);
          } else {
            setLastClickedComponent('deltoid');
          }
        } else {
          setLastClickedComponent(component);
          setLastSelectedComponent(component);
        }
        
        return newSelectedComponents;
      });
    };

    const handleQualityIconClick = (quality) => {
      setSelectedQuality((prevSelectedQuality) => {
        if (prevSelectedQuality.includes(quality)) {
          return prevSelectedQuality.filter((q) => q !== quality);
        } else {
          return [...prevSelectedQuality, quality];
        }
      });
    };

    const handleBackToImageList = () => {
      navigate('/iris/segmentationresults');
    };

    return (
      <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
       {isImageDetailsIsLoading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1
        }}>
          <CircularProgress size="10rem" sx={{ color: '#0092E3' }} />
        </div>
      ) : image ? (
        <Container maxWidth='xl' style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ width: 'fit-content', mb: 2, display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={handleBackToImageList}
              sx={{ 
                mr: 1, 
                p: 0, 
                backgroundColor: '#F9F9F9',
                '&:hover': {
                  backgroundColor: '#E0E0E0',
                },
              }}
              aria-label="back to image list"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant='h5'>
              Segmented Image Details
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <FolderSharedIcon sx={{ color: '#0092E3' }} />
            <Typography sx={{ marginLeft: 0.5, fontFamily: "Open Sans", fontSize: '16px', fontStyle: 'normal', fontWeight: '600', lineHeight: '20px', color: '#0092E3' }}>
              Case Details
            </Typography>
          </Box>

          <TableContainer component={Paper} sx={{ border: '1px solid grey', boxShadow: 'none', marginTop: 1, borderRadius: '8px', overflow: 'hidden'}}>
            <Table aria-label="customized table" sx={{ borderRadius: '8px', borderCollapse: 'separate', borderSpacing: 0 }}>
              <TableBody sx={{ backgroundColor: "#11191f"}}>
                {caseDetailsTableRowData.map((row, index) => (
                  <TableRow key={index}>
                    {row.cells.map((cell, idx) => (
                      <TableCell
                        key={idx}
                        sx={{
                          ...idx === 0 ? caseDetailsHeaderCellStyle : caseDetailsBodyCellStyle,
                          paddingBottom: row.paddingBottom,
                          paddingTop: row.paddingTop
                        }}
                      >
                        {cell}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <br></br>

          <Box>

            <Box display="flex" alignItems="center">
              <MedicalInformationIcon sx={{ color: '#0092E3' }} />
              <Typography sx={{ marginLeft: 0.5, fontFamily: "Open Sans", fontSize: '16px', fontStyle: 'normal', fontWeight: '600', lineHeight: '20px', color: '#0092E3' }}>
                CT Details
              </Typography>
            </Box>
            {image && (
              <Typography sx={{ marginLeft: 3.5, fontFamily: "Open Sans", fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight: '20px', color: '#F9F9F9' }}>
                Side: {image.surgerySide || 'N/A'} | Beta Angle: N/A | Retroversion: N/A | Subluxation: N/A
              </Typography>
            )}
          </Box>

          <Box sx={{ p: 2, marginTop: 1, border: '1px solid grey', display: 'flex', flexDirection: 'column', gap: 3, borderRadius: '8px' }}>
            <Grid container spacing={2}>
              <Grid item xs={1.5}>
                <Tooltip title="Bone Quality is not yet available for Scapula, Humerus and Clavicle" arrow>
                  <span>
                    <Button 
                      disabled
                      variant="contained" 
                      sx={{
                        ...shoulderComponentButtonStyles, 
                        mb: 2, 
                        backgroundColor: selectedQuality.includes('bone') ? '#0092E3' : '#444',
                        '&:hover': {
                          backgroundColor: selectedQuality.includes('bone') ? '#0092E3' : '#555',
                        },
                        '&.Mui-disabled': {
                          backgroundColor: selectedQuality === 'bone' ? '#0092E3' : shoulderComponentButtonStyles.backgroundColor,
                          color: '#F9F9F9',
                          opacity: 0.5,
                        },
                      }}
                      onClick={() => handleQualityIconClick('bone')}
                    >
                      Bone Quality
                    </Button>
                  </span>
                </Tooltip>
                <Button 
                  variant="contained" 
                  sx={{ 
                    ...shoulderComponentButtonStyles, 
                    mb: 2, 
                    backgroundColor: selectedQuality.includes('muscle') ? '#0092E3' : '#444',
                    '&:hover': {
                      backgroundColor: selectedQuality.includes('muscle') ? '#0092E3' : '#555',
                    }
                  }}
                  onClick={() => handleQualityIconClick('muscle')}
                >
                  Muscle Quality
                </Button>
              </Grid>
              <BonesShoulderComponentIconBoxes 
                selectedComponents={selectedComponents}
                handleIconClick={handleIconClick}
              />
              <MuscleShoulderComponentIconBoxes 
                selectedComponents={selectedComponents}
                handleIconClick={handleIconClick}
              />
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {image.dicomUrl && image.niftiMaskUrls && (
                  <DicomNiftiViewer 
                    subnum={image.subnum}
                    zipUrl={image?.dicomUrl} 
                    niftiUrls={selectedComponents.reduce((urls, component) => {
                      if (component !== 'scapula' && image['niftiMaskUrls'][component]) {
                        urls.push({
                          url: image['niftiMaskUrls'][component],
                          color: componentColorMap[component] || { r: 255, g: 0, b: 0, a: 200 }
                        });
                      }
                      return urls;
                    }, [])}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <div style={{ backgroundColor: 'black' }}>
                  {image.stlUrls && (
                    <ShoulderSTLViewer 
                      selectedComponents={selectedComponents}
                      selectedQuality={selectedQuality}
                      stlUrls={image?.stlUrls}                   
                    />
                  )}
                </div>
              </Grid>
            </Grid>
            {labResults && (
              <LabResultsGrid 
                labResults={labResults} 
                lastClickedComponent={lastClickedComponent}
                lastSelectedComponent={lastSelectedComponent}
              />
            )}
            <ShoulderCaseLabeling image={image}/>
          </Box>
        </Container>
      ): (
        <Typography>No image data available</Typography>
      )}
    </div>
  );
}
