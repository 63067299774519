import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AnalyticsIcon from '@mui/icons-material/Analytics';


import LabResult from './LabResult';

const labResultsConfig = [
  { name: 'Fat Ratio Percentage', key: 'fat_ratio_percent' },
  { name: 'Normalised Volume', key: 'n_volume' },
  { name: 'Flatness', key: 'original_shape_Flatness' },
  { name: 'Sphericity', key: 'original_shape_Sphericity' },
  { name: 'Atrophy', key: 'atrophy' },
];

const organs = ['scapula', 'deltoid', 'humerus', 'clavicle', 'infraspinatus', 'supraspinatus', 'subscapularis'];

const OrganResults = ({ organ, labResults }) => {
  if (!labResults[organ]) {
    return (
        <Alert severity="info">No metrics are available for {organ}.</Alert>
    );
  }

  const availableMetrics = labResultsConfig.filter(({ key }) => labResults[organ][key]);

  if (availableMetrics.length === 0) {
    return (
        <Alert severity="info">No metrics are available for {organ}.</Alert>
    );
  }

  return (
    <Grid container rowSpacing={2} spacing={5}>
      {availableMetrics.map(({ name, key }) => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={`${organ}-${key}`}>
          <LabResult
            name={name}
            min={labResults[organ][key].q05}
            max={labResults[organ][key].q95}
            actual={labResults[organ][key].value}
            normalRange={[labResults[organ][key].q25, labResults[organ][key].q75]}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const LabResultsGrid = ({ labResults, lastClickedComponent, lastSelectedComponent }) => {
  const [value, setValue] = React.useState(organs.indexOf('scapula'));
  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    const componentToShow = lastClickedComponent || lastSelectedComponent;
    const index = organs.indexOf(componentToShow);
    if (index !== -1) {
      setValue(index);
    }
  }, [lastClickedComponent, lastSelectedComponent]);

  if (!labResults) return null;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion 
      expanded={expanded} 
      onChange={handleAccordionChange} 
      sx={{ 
        border: '1px solid grey',
        '& .MuiAccordionSummary-expandIconWrapper': {
          color: '#F9F9F9',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: '#0092E3' }}/>}
        aria-controls="lab-results-content"
        id="lab-results-header"
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AnalyticsIcon sx={{ marginRight: 1, color: '#0092E3' }} />
            <Typography sx={{ fontFamily: "Open Sans", fontSize: '16px', fontStyle: 'normal', fontWeight: '600', lineHeight: '20px', color: '#0092E3' }}>
              Metrics
            </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '400px' }}>
          <Tabs 
            value={value} 
            onChange={handleChange} 
            variant="scrollable" 
            scrollButtons="auto"
            sx={{
              '& .MuiTab-root:not(.Mui-selected)': {
                color: '#F9F9F9',
              },
            }}
          >
            {organs.map((organ, index) => (
              <Tab label={organ.charAt(0).toUpperCase() + organ.slice(1)} key={organ} />
            ))}
          </Tabs>
          <Box sx={{ flexGrow: 1, overflow: 'auto', position: 'relative' }}>
            {organs.map((organ, index) => (
              <Box
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                key={organ}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  overflow: 'auto',
                  padding: 3,
                  display: value === index ? 'flex' : 'none',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <OrganResults organ={organ} labResults={labResults} />
              </Box>
            ))}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default LabResultsGrid;